<template>
  <a-space style="color: #FF6026;">
    <a-icon type="windows" style="width: 18px;height: 18px"/>
    <span style="font-size: 16px;">{{ title }}</span> <span v-if="subTitle" style="font-size: 14px;color: #666;font-weight: normal">{{ subTitle }}</span>
  </a-space>

</template>
<script>

export default {
  name: 'winTitle',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  }
}
</script>
