<template>
    <div class="userInfo">
        <a-tabs :activeKey="activeKey">
            <a-tab-pane key="0" tab="账号信息">
            </a-tab-pane>
        </a-tabs>
        <a-descriptions :column="4">
            <a-descriptions-item label="账号">
                {{ setUserInfo().nickname || '-' }}
            </a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="4" :colon="false">
            <a-descriptions-item label="绑定手机">
                <a-input style="width: 400px;height: 38px;line-height: 36px;" :disabled="true" placeholder="暂无绑定手机"
                    v-model="phone"></a-input>
                <a-button type="link" class="edit-btn" @click="handleChangePhone">
                    <span>更换手机</span>
                </a-button>
            </a-descriptions-item>

        </a-descriptions>
        <a-descriptions :column="4" :colon="false" class="spec">
            <a-descriptions-item label="  密码">
                <a-input style="width: 400px;height: 38px;line-height: 36px;" :disabled="true" placeholder="密码"
                    value="********"></a-input>
                <a-button type="link" class="edit-btn" @click="handleChangePassword">
                    <span>修改密码</span>
                </a-button>
            </a-descriptions-item>

        </a-descriptions>
        <a-descriptions :column="4" :colon="false">
            <a-descriptions-item label="当前头像">
                <a-upload name="file" :showUploadList="false" accept="image/png, image/jpeg, image/jpg, image/gif"
                    :multiple="false" :action="uploadAction" :headers="headers" :data="{ dir: 'images' }"
                    :fileList="fileList" @change="handleChangeImage">
                    <a-avatar class="avatar" :src="setUserInfo().avatar || '/bg_avatar.png'" />
                </a-upload>
            </a-descriptions-item>

        </a-descriptions>
        <changePassword ref="changePasswordRef" />
        <changePhone ref="changePhoneRef" @ok="handleUpDateInfo" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import changePassword from './modules/changePassword.vue'
import changePhone from './modules/changePhone.vue'
import { getAction, postAction } from '@/api/manage'
export default {
    components: {
        changePassword,
        changePhone
    },
    data() {
        return {
            uploadAction: `${process.env.VUE_APP_API_BASE_URL}/file/images/upload`,
            headers: {},
            fileList: [],
            avatarUrl: null,
            activeKey: '0',
            phone: null,
            userInfo: {},
        }
    },
    created() {
        this.headers = { token: JSON.parse(localStorage.getItem('authInfo'))['Authorization'] }
        getAction('/user/info', { userId: this.setUserInfo().userId }).then(res => {
            const { success, data } = res;

            if (success && data) {
                data.phone && (this.phone = this.encryptPhoneNumber(data.phone))
                this.userInfo = data
            }
        })
    },
    methods: {
        ...mapGetters(['setUserInfo']),
        encryptPhoneNumber(phoneNumber) {
            // 确保手机号码是字符串类型  
            phoneNumber = String(phoneNumber);
            // 获取手机号码的长度  
            let length = phoneNumber.length;
            // 如果手机号码长度不是11位，则返回原始号码  
            if (length !== 11) {
                return phoneNumber;
            }
            // 使用字符串的slice方法截取号码的部分，并把中间四位替换成星号  
            let encryptedNumber = phoneNumber.slice(0, 3) + '****' + phoneNumber.slice(7);
            return encryptedNumber;
        },
        handleChangeImage({ file, fileList }) {
            console.warn(fileList)
            if (file.status === 'done') {
                // fileLists = fileLists.splice(-1, 1)
                if (file.response) {
                    if (file.response.success) {
                        const { data } = file.response
                        this.avatarUrl = data.url
                        this.handleSaveUserAvatar()
                    }
                }
            }
            this.fileList = fileList.filter(i => i.status != 'error')
        },
        /* 更新头像信息 */
        handleSaveUserAvatar() {
            postAction('/user/pc/user/replaceUserAvatar', {
                userId: this.setUserInfo().userId,
                email: this.setUserInfo().email,
                avatar: this.avatarUrl
            }).then(res => {
                const { message, success } = res
                this.fileList = []
                if (success) {
                    this.$message.success(message)
                    this.handleUpDateInfo()
                } else {
                    this.$message.warning(message)
                }
            })
        },
        handleChangePassword() {
            this.$refs.changePasswordRef.handleEdit()
        },
        handleChangePhone() {
            this.$refs.changePhoneRef.handleEdit()
        },
        handleUpDateInfo() {
            getAction('/user/info', { userId: this.setUserInfo().userId }).then((res) => {
                const { success, data } = res
                if (success) {
                    let userInfo = JSON.stringify(data)
                    this.userInfo = data
                    data.phone && (this.phone = this.encryptPhoneNumber(data.phone))
                    localStorage.setItem('userInfo', userInfo)
                    this.$store.commit('setUserInfo', data)
                }
            })
        },
    },
}
</script>

<style lang="less" scoped>
.userInfo {
    padding: 6px 25px 25px;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #fff;

    ::v-deep .ant-tabs-bar {
        border-color: #efefef;
    }

    ::v-deep .ant-tabs-nav .ant-tabs-tab {
        padding: 12px 0;
    }

    ::v-deep .ant-descriptions-item-label {
        color: #000;
    }

    ::v-deep .ant-descriptions-item-content {
        color: #131212;
    }

    ::v-deep .ant-divider {
        margin-top: 0;
        background: #EFEFEF !important;
        margin-bottom: 12px;
    }

    .info-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;

        .left-side {
            display: flex;
            flex-direction: column;
            color: #131212;
            line-height: 22px;

            .label {
                margin-bottom: 6px;
            }

            .value {
                color: #8D8E99;
            }


        }

        .edit-btn {
            display: flex;
            align-items: center;

            .edit {
                width: 16px;
                margin-right: 4px;
            }
        }
    }
}

.spec {
    ::v-deep .ant-descriptions-item-label {
        width: 66px !important;
        text-align: right
    }
}

.avatar {
    width: 100px;
    height: 100px;
    cursor: pointer;
    position: relative;

    &::before {
        display: block;
        content: '';
        width: 100px;
        height: 100px;
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.25);
    }

    &::after {
        display: block;
        content: '';
        width: 16px;
        height: 16px;
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: url('/photo.png');
        background-size: 100% 100%;
    }
}
</style>